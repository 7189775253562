.sc-schedule {
  &__title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    color: #7739EA;
    margin: 0 0 40px; }
  .sc-table {
    display: block;
    position: relative;
    width: 100%;
    .title {
      display: block;
      font-size: 24px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 16px;
      max-width: 70%;
      color: #322F36;
      text-decoration: none; }
    .badge {
      margin-bottom: 16px;
      span {
        display: inline-block;
        padding: 0 6px;
        color: #FFF;
        background: #232933; } }
    .sc-speakers {
      display: flex;
      flex-wrap: wrap;
      max-width: 70%;
      margin-bottom: -24px;
      .item {
        max-width: 50%;
        flex: 0 0 50%;
        &__meta {
          font-size: 14px;
          line-height: 20px; }
        &__name {
          font-weight: 600; }
        &__img {
          max-width: 36px;
          flex: 0 0 36px;
          height: 36px;
          overflow: hidden;
          border-radius: 18px;
          border: 2px solid #FFF;
          &.avatar {
            background-image: url(../images/avatar.webp);
            background-size: 40px;
            background-position: center; } }
        &__tbc {
          background: #71717A;
          color: #FFF;
          padding: 0 6px;
          display: inline-block;
          font-size: 12px;
          line-height: 16px; }
        &__content {
          max-width: calc(100% - 36px);
          flex: 0 0 calc(100% - 36px);
          padding-left: 12px;
          padding-right: 24px; }
        &__wrap {
          display: flex;
          margin: 0 0 24px; } } }
    .action {
      max-width: 70%;
      position: absolute;
      top: 0;
      right: 20px;
      padding: 24px 56px 24px 24px;
      opacity: 0;
      visibility: hidden;
      transition: all .3s ease-in; }
    .sc-nav {
      display: flex;
      padding: 0;
      align-items: stretch;
      max-width: 100%;
      flex: 0 0 100%;
      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-grow: 1;
        font-size: 18px;
        line-height: 24px;
        transition: all .3s ease-in-out;
        cursor: pointer;
        padding: 24px;
        &:hover,
        &.active {
          background: #7739EA;
          color: #FFF; }
        &:nth-child(2):hover,
        &:nth-child(2).active {
          background: #2CA4BF; }
        &:nth-child(3):hover {}
        &:nth-child(3).active {
          background: #DC2F66; } } }
    .sc-tabpane {
      display: none;
      &.fade {
        opacity: 0;
        transition: all .3s ease-in;
        &.show {
          opacity: 1; } }
      &.active {
        display: block; } } }

  .sc-tr {
    z-index: 1;
    position: relative;
    display: flex;
    border: 1px solid #E7E4E8;
    transition: all .3s ease-in;
    &+ .sc-tr,
    &+ .sc-thead {
      margin-top: -1px; } }
  .sc-tbody {
    .sc-tr {
      &::before {
        content: "";
        pointer-events: none;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../images/accent-schedule-01.webp);
        background-position: bottom right;
        background-size: 30% auto;
        background-repeat: no-repeat;
        opacity: 0;
        transition: all .3s ease-in; }
      &.bg-green {
        &::before {
          background-image: url(../images/accent-schedule-02.webp); }
        &:hover {
          background-color: #D9F8FF;
          border-color: #2CA4BF;
          .sc-th {
            border-right-color: #2CA4BF; } } }
      &.bg-red {
        &::before {
          background-image: url(../images/accent-schedule-03.webp);
          background-size: 20% auto; }
        &:hover {
          background: #FFF2F8;
          border-color: #DC2F66;
          .sc-th {
            border-right-color: #DC2F66; } } }
      &:hover {
        z-index: 2;
        background: #F1E8FF;
        border: 1px solid #7739EA;
        box-shadow: 0px 254px 71px 0px rgba(0, 0, 0, 0.00), 0px 163px 65px 0px rgba(0, 0, 0, 0.01), 0px 91px 55px 0px rgba(0, 0, 0, 0.03), 0px 41px 41px 0px rgba(0, 0, 0, 0.04), 0px 10px 22px 0px rgba(0, 0, 0, 0.05);
        .action {
          opacity: 1;
          right: 0;
          visibility: visible; }
        &::before {
          opacity: 1; }
        .sc-th {
          border-right-color: #7739EA; } } } }
  .sc-tbody + .sc-thead {
    margin-top: -1px; }
  .sc-th {
    display: block;
    max-width: 256px;
    flex: 0 0 256px;
    border-right: 1px solid #E7E4E8;
    padding: 24px;
    transition: all .3s ease-in;
    &--sm {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400; }
    &.bg-gray {
      background: #F3EFED; }
    &.bg-dark {
      color: #FFF;
      background: #322F36; } }
  .sc-td {
    display: block;
    max-width: calc(100% - 256px);
    flex: 0 0 calc(100% - 256px);
    padding: 24px;
    transition: all .3s ease-in;
    &.bg-gray {
      background: #F3EFED; }
    &.bg-dark {
      color: #FFF;
      background: #322F36; } }
  .sc-thead {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: -1px;
    &--nav {
      position: sticky;
      top: 80px;
      z-index: 20;
      background: #FFF; } }
  .sc-note {
    padding: 6px;
    color: #FFF;
    background: #322F36; }

  &__wrap {
    padding-top: 40px;
    padding-bottom: 80px; }

  @include maxtablet {
    .hide-sm {
      display: none; }
    .sc-table {
      .title {
        max-width: 100%; }
      .sc-speakers {
        max-width: 100%;
        .item {
          max-width: 100%;
          flex: 0 0 100%; } }
      .action {
        position: relative;
        max-height: 0;
        padding: 0;
        width: 100%; } }
    .sc-tr {
      flex-direction: column;
      &::before {
        display: none; } }
    .sc-th,
    .sc-td {
      max-width: 100%;
      flex: 0 0 100%; }
    .sc-th {
      border-right: 0; }
    .sc-thead {
      &.sm-bg {
        .sc-th {
          padding-bottom: 0;
          background: #F3EFED; } }
      &.sm-center {
        text-align: center;
        .sc-nav {
          border-top: 1px solid #E7E4E8;
          &__item {
            padding: 24px 12px; } } } }
    .sc-tbody {
      .sc-tr {
        .sc-th {
          padding-bottom: 0; } } }
    .sc-tbody .sc-tr:hover {
      .action {
        max-height: 100px;
        padding-top: 48px; } } } }

