.cs-masthead {
  position: relative;
  padding-top: 96px;
  padding-bottom: 64px;
  background: #6F4AEB;
  min-height: 100vh;
  display: flex;
  align-items: center;
  .cs-masthead__meta {
    font-size: 24px;
    line-height: 30px;
    color: #FFF;
    display: flex;
    margin-bottom: 24px;
    .date {
      display: block;
      font-weight: 600;
      padding: 8px 16px;
      background: #322F36; }
    .location {
      display: block;
      padding: 8px 16px;
      background: #2CA4BF;
      border: 1px solid rgba(255, 255, 255, 0.05); } }
  .cs-masthead__title {
    display: inline;
    color: #FFF;
    font-size: 64px;
    line-height: 110%;
    margin-bottom: 24px;
    .title-sm {
      display: none; }
    .bg {
      z-index: 2;
      display: inline-block;
      vertical-align: bottom;
      position: relative;
      padding: 10px 18px 18px;
      overflow: hidden;
      margin-left: 0;
      img,
      span {
        position: relative;
        z-index: 2; }
      &::before {
        z-index: 1;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #4B2CB6; }
      &:nth-child(2) {
        z-index: 1;
        margin-top: -10px; }
      &.reveal {
        transform: translate3d(0, 0 , 0);
        &::before {
          max-width: 0;
          transition: all .3s ease-in-out; } }
      &.reveal_visible {
        transform: translate3d(0, 0, 0);
        &::before {
          max-width: calc(100% + 20px); } }
      img {
        display: inline-block;
        max-height: 67px;
        margin-right: 16px;
        &:last-child {
          margin-right: 0; }
        &.reveal {
          transition-delay: 0.3s; } } } }
  .cs-masthead__desc {
    padding-top: 32px;
    font-size: 18px;
    line-height: 28px;
    max-width: 900px;
    color: #FFF; }
  .cs-masthead__action {
    padding-top: 56px;
    margin-left: -12px;
    margin-right: -12px;
    .btn {
      margin-left: 12px;
      margin-right: 12px; } }
  &__accent {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    .accent-l,
    .accent-r {
      position: absolute;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat; }
    .accent-l {
      background-image: url(../images/hero-accent-l.webp);
      background-size: 160px;
      background-position: left top; }
    .accent-r {
      background-image: url(../images/hero-accent-r.webp);
      background-size: contain;
      background-position: right center; } }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    video {
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center; } }
  &__wrap {
    z-index: 3;
    position: relative;
    padding-top: 64px;
    padding-bottom: 64px; }

  &.cs-masthead--small {
    padding-top: 96px;
    padding-bottom: 0;
    background: #7739EA;
    min-height: auto;
    .cs-masthead__wrap {
      padding-top: 64px;
      padding-bottom: 64p; }
    .cs-masthead__accent {
      .accent-r {
        background-image: url(../images/hero-accent-speakers-right.png);
        background-size: auto 100%; }
      .accent-l {
        background-image: url(../images/hero-accent-speakers-left.png);
        background-size: auto 40%;
        background-position: bottom left; } } }

  &.cs-masthead--green {
    background: #2CA4BF;
    padding-top: 96px;
    padding-bottom: 0;
    min-height: auto;
    .cs-masthead__wrap {
      padding-top: 64px;
      padding-bottom: 64p; }
    .cs-masthead__accent {
      .accent-r {
        background-image: url(../images/hero-accent-green.webp);
        background-size: auto 100%;
        opacity: 0.5; }
      .accent-l {
        display: none; } } }

  &.cs-masthead--sponsors,
  &.cs-masthead--about {
    background: #FFF;
    min-height: auto;
    padding-bottom: 0;
    overflow: hidden;
    .swiper-nav {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 20;
      .btn-prev,
      .btn-next {
        padding: 20px;
        background: #7739EA;
        cursor: pointer;
        &:after {
          content: "";
          display: block;
          width: 40px;
          height: 40px;
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none"><g id="arrow"><path id="Vector" d="M38.5484 15.8415L54.7641 32.0571L38.5484 48.2727M9.34961 32.0571H54.3099" stroke="%23F3EFED" stroke-width="5.34288" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></g></svg>');
          background-repeat: no-repeat;
          background-size: contain; } }
      .btn-prev {
        transform: scaleX(-1); } }
    .cs-masthead {
      &__title {
        color: #322F36;
        font-size: 40px;
        line-height: 120%;
        font-weight: 600; }
      &__desc {
        color: #232933; }
      &__left {
        display: block;
        max-width: 48%;
        flex: 0 0 48%;
        padding-right: 64px; }
      &__right {
        display: block;
        max-width: 52%;
        flex: 0 0 52%; }
      &__wrap {
        display: flex;
        padding-top: 80px;
        padding-bottom: 80px; }
      &__slider {
        .item {
          width: 100%;
          padding-top: 60%;
          &__img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center; } } }
      &__accordion {
        display: flex;
        gap: 16px;
        .item {
          display: block;
          width: 100%;
          height: 480px;
          max-width: 120px;
          flex: 0 0 120px;
          transition: all .2s ease;
          position: relative;
          cursor: pointer;
          &__img {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: right center; }
          &.active {
            max-width: calc(100% - 136px);
            flex: 0 0 calc(100% - 136px); } } } } }


  @include responsive(1200px) {
    .cs-masthead__meta {
      font-size: 16px;
      line-height: 24px;
      margin-left: -12px; }
    .cs-masthead__title {
      font-size: 48px;
      line-height: 140%;
      .title-sm {
        display: block;
        position: relative;
        padding: 12px 16px;
        margin-left: -12px;
        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: #4B2CB6;
          z-index: -1; } }
      .bg {
        display: none; } }
    .cs-masthead__desc {
      font-size: 16px;
      line-height: 24px; }

    &.cs-masthead--sponsors,
    &.cs-masthead--about {
      .cs-masthead {
        &__left,
        &__right {
          max-width: 100%;
          flex: 0 0 100%; }
        &__left {
          padding-right: 0; }
        &__right {
          padding-top: 40px;
          .swiper-nav {
            position: relative;
            margin-top: 8px;
            width: 100%;
            .btn-prev,
            .btn-next {
              width: 50%;
              &::after {
                margin-left: auto; } } } }
        &__wrap {
          display: flex;
          flex-wrap: wrap;
          padding-top: 32px;
          padding-bottom: 32px; }
        &__accordion {
          .item {
            height: 30vh;
            max-width: 10%;
            flex: 0 0 10%;
            &.active {
              max-width: calc(80% - 32px);
              flex: 0 0 calc(80% - 32px); } } } } }
    &.cs-masthead--about {
      .cs-masthead {
        &__accordion {
          margin-left: -16px;
          margin-right: -16px; }
        &__left {
          order: 2;
          padding-top: 40px; }
        &__right {
          order: 1;
          padding-top: 0; } } } }

  @include mobile() {
    .cs-masthead__title {
      font-size: 32px;
      line-height: 48px;
      .bg {
        display: none; } }
    .cs-masthead__action {
      margin-bottom: -16px;
      .btn {
        display: block;
        margin-bottom: 16px; } }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px;
      padding-left: 16px;
      padding-right: 16px; } } }
