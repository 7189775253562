/* ------------------------------------------------------------------------------------------------ */
/* Common */
/* ------------------------------------------------------------------------------------------------ */

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none; }

/* Common */

hr {
  border: 0;
  border-top: 1px solid #e2e2e2; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  &::-webkit-scrollbar {
    width: 12px; }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px; }
  &::-webkit-scrollbar-thumb {
    background: rgba($primary,.8);
    border-radius: 5px;
 }    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5)
  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(#333,.4); } }

.hidden {
  display: none; }

input,textarea {
  padding: 0;
  border-radius: 0; }

.block {
  display: block; }


p {
  margin: 0 0 24px;
  padding: 0;
  &:last-child {
    margin-bottom: 0; }
  strong,b {
    font-weightL bold {} }
  em,i {
    font-style: italic; }
  u {
    text-decoration: underline; } }

h1, h2, h3, h4 {
  color: #322F36;
  @include inter-600();
  margin: 0 0 8px;
  line-height: normal; }

article {
  p {
    word-break: break-word;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    clear: both; }
  a {
    &:visited {
      text-decoration: underline; }
    &:hover {
      text-decoration: none; } }

  li {
    ul, ol {
      margin: 0 24px; } }

  ul, ol {
    margin: 0 0 24px;
    padding-left: 32px;
    &:last-child {
      margin: 0; }
    li {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0; } } }


  ul {
    list-style-type: disc; }
  ol {
    list-style-type: decimal;
    ol {
      list-style: upper-alpha;
      ol {
        list-style: lower-roman;
        ol {
          list-style: lower-alpha; } } } } }

img {
  max-width: 100%;
  height: auto; }


html, body {
  @include inter-400();
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: $text;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  background: #FFF;
  input {
    font-family: inherit; } }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }
