/* ------------------------------------------------------------------------------------------------ */
/* Footer */
/* ------------------------------------------------------------------------------------------------ */

.footer {
  overflow: hidden;
  background: #322F36;
  color: #FFF;
  .container-border {
    &::before,
    &::after {
      height: calc(100% + 80px);
      top: -40px;
      border-left: 1px solid rgba(144, 122, 248, 0.10); } }
  a {
    color: #FFF;
    &:hover {
      color: #FFF;
      text-decoration: underline; } }

  &__logo {
    display: block;
    margin: 0;
    padding: 0 8px;
    img {
      display: block;
      height: 24px;
      width: auto; } }

  &__desc {
    font-size: 18px;
    line-height: 28.8px;
    max-width: 359px; }

  &__info {
    .title {
      font-size: 12px;
      line-height: 20px;
      margin: 0 0 32px; }
    .items {
      display: flex;
      justify-content: space-between;
      margin-left: -12px;
      margin-right: -12px;
      .item {
        display: block;
        padding-left: 12px;
        padding-right: 12px;
        &__title {
          display: block;
          @include inter-600(); } } } }

  &__copy {
    font-size: 12px;
    line-height: 20px;
    padding-left: 8px;
    padding-right: 8px; }

  &__socials {
    padding-left: 8px;
    padding-right: 8px;
    .items {
      display: flex;
      flex-wrap: wrap;
      margin-left: -8px;
      margin-right: -8px;
      .item {
        padding-left: 8px;
        padding-right: 8px;
        display: block;
        &> a {
          display: block;
          transition: all .3s ease-in-out;
          &:hover {
            opacity: 0.5; }
          &> img {
            display: block;
            height: 24px;
            width: 24px;
            object-fit: contain;
            object-position: center; } } } } }

  &__top {
    padding-top: 80px;
    padding-bottom: 80px;
    border-top: 1px solid #4E4A51;
    border-bottom: 1px solid #4E4A51;
    .col-left {
      display: block;
      width: 100%;
      max-width: 44%;
      flex: 0 0 44%;
      padding-left: 8px;
      padding-right: 8px; }
    .col-right {
      display: block;
      width: 100%;
      max-width: 56%;
      flex: 0 0 56%;
      padding-left: 8px;
      padding-right: 8px; } }

  &__bot {
    padding-top: 40px;
    padding-bottom: 40px;
    &> .container > .row {
      justify-content: space-between; } }

  .back-to-top {
    z-index: 100;
    display: block;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    background: #322F36;
    right: 40px;
    bottom: calc(20% - 40px);
    width: 56px;
    height: 56px;
    cursor: pointer;
    transition: all .3s ease-in;
    border: 1px solid #FFFFFF;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../images/ic-btt.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center 6px; }
    &.show {
      opacity: 1;
      visibility: visible;
      bottom: 20%; }
    &:hover {
      &:after {
        @include bounce-up(); } } }

  @include maxtablet() {
    &__top {
      .col-left {
        max-width: 100%;
        flex: 0 0 100%; }
      .col-right {
        padding-top: 64px;
        max-width: 100%;
        flex: 0 0 100%; } }
    .back-to-top {
      bottom: 40px;
      &.show {
        bottom: 104px; } } }
  @include mobile {
    &__info {
      .items {
        flex-wrap: wrap;
        .item {
          max-width: 100%;
          flex: 0 0 100%;
          margin-bottom: 24px;
          &:last-child {
            margin-bottom: 0; } } } }
    &__logo {
      margin-bottom: 24px;
      img {
        margin: 0 auto; } }
    &__copy {
      margin-bottom: 24px;
      text-align: center; }
    &__socials .items {
      justify-content: center; }
    &__top {
      padding-top: 48px;
      padding-bottom: 48px;
      .col-left,
      .col-right {
        padding-left: 16px;
        padding-right: 16px; } }
    &__bot {
      .container {
        padding-left: 16px;
        padding-right: 16px;
        &> .row {
          flex-direction: column;
          justify-content: center; } } }
    .back-to-top {
      right: 16px; } } }




