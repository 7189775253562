@mixin bounce() {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1); }

@keyframes bounce {
  0% {
    transform: scale(1,1) translateX(0); }
  10% {
    transform: scale(1.1,.9) translateX(0); }
  30% {
    transform: scale(.9,1.1) translateX(-4px); }
  50% {
    transform: scale(1.05,.95) translateX(6px); }
  57% {
    transform: scale(1,1) translateX(-2px); }
  64% {
    transform: scale(1,1) translateX(0); }
  100% {
    transform: scale(1,1) translateX(0); } }

@mixin bounce-reverse() {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce-reverse;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1); }


@keyframes bounce-reverse {
  0% {
    transform: scale(1,1) translateX(0); }
  10% {
    transform: scale(1.1,.9) translateX(0); }
  30% {
    transform: scale(.9,1.1) translateX(4px); }
  50% {
    transform: scale(1.05,.95) translateX(6px); }
  57% {
    transform: scale(1,1) translateX(2px); }
  64% {
    transform: scale(1,1) translateX(0); }
  100% {
    transform: scale(1,1) translateX(0); } }

@mixin bounce-up() {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce-up;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1); }

@keyframes bounce-up {
  0% {
    transform: scale(1,1) translateY(0); }
  10% {
    transform: scale(1.1,.9) translateY(0); }
  30% {
    transform: scale(.9,1.1) translateY(-4px); }
  50% {
    transform: scale(1.05,.95) translateY(6px); }
  57% {
    transform: scale(1,1) translateY(-2px); }
  64% {
    transform: scale(1,1) translateY(0); }
  100% {
    transform: scale(1,1) translateY(0); } }

@mixin marquee() {
  animation: marquee 10s linear infinite; }

@keyframes marquee {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(-100%); } }

@keyframes scroll-x {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(calc(-100%)); } }

@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes flip {
  0% {
    transform: perspective(400px) rotateX(0deg); }

  50% {
    transform: perspective(400px) rotateX(90deg); }

  100% {
    transform: perspective(400px) rotateX(180deg); } }

@mixin pulse($r, $g, $b, $name) {
  box-shadow: 0 0 0 rgba($r, $g, $b, 0.4);
  animation: $name 2s infinite;

  @keyframes #{$name} {
    0% {
      box-shadow: 0 0 0 0 rgba($r, $g, $b, 0.4); }
    70% {
      box-shadow: 0 0 0 14px rgba($r, $g, $b, 0); }
    100% {
      box-shadow: 0 0 0 0 rgba($r, $g, $b, 0); } } }

@keyframes move {
  0% {
    opacity: 1;
    transform: translateX(0); }
  25% {
    opacity: 0; }
  50% {
    opacity: 0;
    transform: translateX(-10%); }
  50.001% {
    opacity: 0;
    transform: translateX(100%); }
  50.002% {
    opacity: 0;
    transform: translateX(25%); }
  75% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateX(0); } }
