/* ------------------------------------------------------------------------------------------------ */
/* General */
/* ------------------------------------------------------------------------------------------------ */

.btn {
  display: inline-block;
  color: $text;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 14px 24px;
  font-size: 18px;
  line-height: 24px;
  @include inter-600();
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  &:hover {
    color: $text;
    text-decoration: none; }
  &:focus, &.focus {
    outline: 0;
    box-shadow: none; }
  &.disabled,
  &:disabled {
    opacity: 0.5;
    pointer-events: none; }

  // primary button
  &-primary {
    color: #FFFFFF;
    background-color: $primary;
    border-color: $primary;
    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: #FFFFFF;
      background-color: darken($primary, 10%);
      border-color: darken($primary, 10%); } }

  &-primary-border {
    color: $primary;
    background-color: transparent;
    border-color: $primary;
    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: #FFF;
      background-color: $primary;
      border-color: $primary;
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='arrow'%3E%3Cpath id='Vector' d='M14.93 6.35083L21 12.4208L14.93 18.4908M4 12.4208H20.83' stroke='%23FFFFFF' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E"); } } }

  &-white {
    color: $primary;
    background-color: #FFF;
    border-color: #FFF;
    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $primary;
      background-color: #FFF;
      border-color: #FFF; } }

  &-white-border {
    color: #FFF;
    background-color: transparent;
    border-color: #FFF;
    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $primary;
      background-color: #FFF;
      border-color: #FFF;
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='arrow'%3E%3Cpath id='Vector' d='M14.93 6.35083L21 12.4208L14.93 18.4908M4 12.4208H20.83' stroke='%237739EA' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E"); } } }

  &-dark {
    color: $yellow;
    background-color: $text;
    border-color: $text;
    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $yellow;
      background-color: darken($text, 10%);
      border-color: darken($text, 10%); } }

  &-yellow {
    color: #322F36;
    background-color: #FFED51;
    border-color: #FFED51;
    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: #322F36;
      background-color: darken(#FFED51, 10%);
      border-color: darken(#FFED51, 10%); } }

  &-arrow {
    position: relative;
    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='arrow'%3E%3Cpath id='Vector' d='M14.93 6.35083L21 12.4208L14.93 18.4908M4 12.4208H20.83' stroke='%237739EA' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-left: 12px; }
    &:hover::after {
      @include bounce(); } }

  &-arrow-w {
    position: relative;
    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='arrow'%3E%3Cpath id='Vector' d='M14.93 6.35083L21 12.4208L14.93 18.4908M4 12.4208H20.83' stroke='%23fff' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-left: 12px; }
    &:hover::after {
      @include bounce(); } }

  &-arrow-d {
    position: relative;
    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='arrow'%3E%3Cpath id='Vector' d='M14.93 6.35083L21 12.4208L14.93 18.4908M4 12.4208H20.83' stroke='%23322F36' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-left: 12px; }
    &:hover::after {
      @include bounce(); } }

  &-arrow-y {
    position: relative;
    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='arrow'%3E%3Cpath id='Vector' d='M14.93 6.35083L21 12.4208L14.93 18.4908M4 12.4208H20.83' stroke='%23FFED51' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-left: 12px; }
    &:hover::after {
      @include bounce(); } }

  &.ic-animate {
    &::after {
      transform: scale(0);
      opacity: 0;
      transition: all .4s ease-in-out; } }
  &.loading-r {
    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      background-image: url(../images/ic-loading-black.svg);
      background-size: 130%;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 1; }
    &:hover::after {
      animation: none; } }
  &.ic-animate-show::after {
    transform: scale(1);
    opacity: 1; } }

.btn-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  .btn {
    margin-left: 8px;
    margin-right: 8px; }
  &.jc-center {
    justify-content: center; }
  &.jc-end {
    justify-content: end; }

  @include mobile() {
    .btn {
      display: block;
      width: 100%;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0; } } } }

.button-animate {
  position: relative;
  span,
  &::after {
    position: relative;
    z-index: 2; }
  &::before {
    z-index: 1;
    content: "";
    display: block;
    position: absolute;
    width: 15%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.2);
    transition: all 0.4s cubic-bezier(0.67, 0, 0.285, 1); }
  &:hover {
    &::before {
      opacity: 1;
      width: 100%; } } }

.reveal {
  opacity: 0;
  transform: translate3d(0, 100px, 0);
  transition: opacity 0.8s, transform 0.8s;
  &_visible {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.on-loading * {
  pointer-events: none !important; }

//speaker bg
.bgs-01,
.bgs-p,
.bgs-g,
.bgs-r1,
.bgs-r2 {
  position: relative;
  &::before {
    z-index: 1;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: url(../images/speaker-accent-01.webp);
    background-size: contain;
    // background-size: 100% auto
 }    // background-position: left bottom
  * {
    position: relative;
    z-index: 2; } }

.bgs-p::before {
  background-image: url(../images/speaker-accent-01.webp); }
.bgs-g::before {
  background-image: url(../images/speaker-accent-02.webp); }
.bgs-r1::before {
  background-image: url(../images/speaker-accent-04.webp); }
.bgs-r2::before {
  background-image: url(../images/speaker-accent-05.webp); }


// MODAL
[data-toggle="modal"] {
  cursor: pointer; }

.modal-backdrop {
  display: block;
  z-index: 500;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #232933;
  opacity: 0.8; }
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  &__close {
    z-index: 2;
    display: block;
    width: 20px;
    height: 20px;
    background: url(../images/ic-close.webp);
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 16px;
    right: 20px;
    cursor: pointer;
    pointer-events: auto; }
  &__dialog {
    max-width: 60%;
    min-width: 860px;
    margin: 0 0 0 auto;
    position: relative;
    width: auto;
    pointer-events: none;
    transform: translateX(100%);
    transition: transform .3s ease-out; }
  &__content {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100vh;
    background: #0D032D;
    pointer-events: auto;
    .linkedin {
      z-index: 10;
      display: block;
      width: 48px;
      height: 48px;
      background: url(../images/ic-linkedin.webp);
      background-size: contain;
      background-position: center;
      position: absolute;
      top: 276px;
      left: -24px;
      opacity: 1;
      transform: scale(1);
      transition: all .3s ease;
      &:hover {
        transform: scale(1.1); } } }
  &__header {
    display: flex;
    min-height: 300px;
    background: #DC2F66; }
  &__header-img {
    position: relative;
    .img {
      position: relative;
      width: 300px;
      height: 300px; }
    img {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0; }
    .info {
      background: #DC2F66;
      padding: 16px 24px;
      color: #FFF;
      min-height: calc(100% - 300px);
      .title {
        color: #FFF; } } }
  &__header-content {
    position: relative;
    color: #FFF;
    padding: 56px;
    min-height: 300px;
    width: 100%;
    &::before {
      z-index: 1;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(../images/popup-accent.webp);
      background-position: bottom left;
      background-size: auto 45%;
      background-repeat: no-repeat;
      opacity: 0.5;
      pointer-events: none; }
    .meta {
      position: relative;
      z-index: 2;
      display: block;
      margin: 0 0 16px; }
    .title {
      position: relative;
      z-index: 2;
      font-size: 24px;
      line-height: 30px;
      font-weight: 600;
      color: #FFF;
      margin: 0 0 16px; }
    .badge {
      position: relative;
      z-index: 2;
      margin: 0 0 16px;
      span {
        display: inline-block;
        padding: 0 6px;
        background: #232933;
        color: #FFF; } }
    .subtitle {
      position: relative;
      z-index: 2;
      font-size: 18px;
      line-height: 28px;
      color: #FFF; } }
  &__header-img + .modal__header-content {
    max-width: calc(100% - 300px);
    flex: 0 0 calc(100% - 300px); }
  &__body {
    padding: 32px 56px;
    overflow: auto;
    color: #FFF;
    height: calc(100vh - 440px);
    padding-bottom: 64px;
    h1, h2, h3, h4 {
      color: #FFF;
      font-weight: 400;
      margin-bottom: 24px; }
    a:not(.btn) {
      color: #FFF;
      text-decoration: underline;
      opacity: 1;
      transition: all .3s ease;
      &:hover {
        text-decoration: underline;
        opacity: 0.5; } }
    &::-webkit-scrollbar {
      width: 10px; }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 0; }
    &::-webkit-scrollbar-thumb {
      background: #E7E4E8;
      border-radius: 0;
      border: 4px solid #0D032D; }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(#333,.4); } }

  .schedule {
    position: relative;
    padding: 24px 24px 32px;
    h1, h2, h3, h4 {
      color: #322F36; }
    &__title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 300;
      color: #322F36;
      margin: 0 0 16px;
      max-width: 70%; }
    .item {
      &__meta {
        display: inline-block;
        margin-bottom: 24px;
        border: 1px solid #FFF;
        color: #FFF;
        span {
          display: inline-block;
          position: relative;
          padding: 0 6px;
          font-size: 15px;
          line-height: 24px;
          &+ span {
            border-left: 1px solid #FFF; } } }
      &__outline {
        font-size: 18px;
        line-height: 28.8px;
        margin-bottom: 4px; }
      &__title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 600; }
      &__action {
        position: relative;
        padding-top: 80px; }
      &+ .item {
        margin-top: 24px; } } }

  .speaker {
    &__title {
      margin: 24px 0;
      font-size: 24px;
      line-height: 30px; }
    .sc-speakers {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
      margin-bottom: -24px;
      .item {
        max-width: 50%;
        flex: 0 0 50%;
        &__meta {
          font-size: 14px;
          line-height: 20px; }
        &__name {
          font-size: 24px;
          line-height: 30px;
          font-weight: 600;
          transition: all .3s ease; }
        &__img {
          max-width: 48px;
          flex: 0 0 48px;
          height: 48px;
          overflow: hidden;
          border-radius: 24px;
          border: 2px solid #FFF;
          outline: 2px solid transparent;
          transition: all .3s ease;
          &.avatar {
            background-image: url(../images/avatar.webp);
            background-size: 52px;
            background-position: center; } }
        &__content {
          max-width: calc(100% - 48px);
          flex: 0 0 calc(100% - 48px);
          padding-left: 12px;
          padding-right: 24px; }
        &__wrap {
          display: flex;
          margin: 0 0 24px; }
        &:hover {
          .item__name {
            color: #DECAFF; }
          .item__img {
            border: 2px solid #7739EA;
            outline: 2px solid #7739EA; } } } } }

  &.show {
    .modal__dialog {
      transform: translateX(0); } }

  &--schedule {
    .modal__header-content {
      .title {
        margin-bottom: 16px; }
      &.bg-p {
        background: #7739EA;
        &::before {
          background-image: url(../images/accent-schedule-modal-01.webp);
          background-size: auto 70%;
          background-position: right 0 bottom -80px;
          opacity: 1; } }
      &.bg-g {
        background: #2CA4BF;
        &::before {
          background-image: url(../images/accent-schedule-modal-02.webp);
          background-size: auto 70%;
          background-position: right 0 bottom -80px;
          opacity: 1; } }
      &.bg-r {
        background: #DC2F66;
        &::before {
          background-image: url(../images/accent-schedule-modal-03.webp);
          background-size: auto 70%;
          background-position: right 0 bottom -80px;
          opacity: 1; } } } }

  &--speaker {
    .modal__header-content {
      background: #5030BD;
      &::before {
        display: none; } } }

  @include maxtablet() {
    align-items: center;
    height: 100vh;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none; }
    .schedule {
      padding: 0;
      .items {
        max-width: 100%; }
      .item {
        &__action {
          position: relative;
          top: auto;
          right: auto;
          margin-top: 24px;
          padding-top: 0;
          margin-bottom: 32px; } } }
    .speaker .sc-speakers .item {
      max-width: 100%;
      flex: 0 0 100%; }
    &__content {
      height: auto; }
    &__dialog {
      max-width: calc(100% - 16px);
      min-width: calc(100% - 16px);
      margin: 8px; }
    &__body {
      height: auto; }
    &--schedule {
      .modal__header-content.bg-p,
      .modal__header-content.bg-g,
      .modal__header-content.bg-r, {
        &::before {
          background-size: auto 50%; } } } }

  @include mobile() {
    &__header-content {
      padding: 32px 24px;
      display: flex;
      flex-direction: column; }
    &__body {
      padding: 32px 24px; }
    &--speaker {
      .modal__content {
        .title {
          order: 1; }
        .subtitle {
          order: 2; }
        .linkedin {
          order: 3;
          position: relative;
          left: auto;
          top: auto;
          margin-top: 16px; } } } }

  @include mobilelg() {
    &__header {
      flex-direction: column; }
    &__header-img {
      .img {
        width: 100%;
        padding-top: 100%; } }
    &__header-img + .modal__header-content {
      max-width: 100%;
      flex: 0 0 100%; }
    &__header-content {
      min-height: 240px;
      &::before {
        background-position: bottom right; } } } }


// STICKY WABA
.ic-wa {
  z-index: 101;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 18px;
  right: 18px;
  img {
    z-index: 2;
    position: relative;
    display: block;
    height: 64px;
    width: 64px;
    min-width: 64px; }
  span {
    z-index: 1;
    position: relative;
    display: block;
    width: 100%;
    height: 30px;
    background-color: #25d366;
    font-size: 12px;
    line-height: 14px;
    // padding: 8px 12px 8px 12px
    margin-left: -10px;
    border-radius: 0 20px 20px 0;
    color: #FFF;
    transition: all .3s ease;
    opacity: 0;
    font-size: 0;
    padding: 8px 0;
    max-width: 0; }
  &:hover {
    color: #FFF;
    text-decoration: none;
    span {
      font-size: 12px;
      padding: 8px 12px;
      opacity: 1;
      max-width: 124px; } } }

.cta-waba:not(.show) {
  pointer-events: none !important; }

.has-page-loading {
  z-index: 1000;
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.4);
  background-image: url(../images/loading.svg);
  background-position: center;
  background-size: 64px;
  background-repeat: no-repeat; }
