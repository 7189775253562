.sp-speakers {
  overflow: hidden;
  .item {
    &__img {
      position: relative;
      width: 100%;
      padding-top: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center bottom;
        position: absolute;
        top: 0;
        left: 0; } }
    &__name {
      z-index: 2;
      position: relative;
      font-size: 24px;
      line-height: 30px;
      @include inter-600(); }
    &__job-title {
      z-index: 2;
      position: relative;
      font-size: 14px;
      line-height: 20px; }
    &__content {
      color: #322F36;
      position: relative;
      padding: 16px 24px;
      min-height: 120px;
      overflow: hidden;
      transition: all .3s ease-in-out; }
    &__content-bg {
      z-index: 1;
      display: block;
      position: absolute;
      width: 0;
      height: 100%;
      top: 0;
      right: 0;
      opacity: 0;
      transition: all .3s ease-in-out; }
    &.bg-01 {
      .item__img {
        background: url(../images/img-bg-01.webp);
        background-size: contain; }
      .item__content-bg {
        background: #DC2F66; } }
    &.bg-02 {
      .item__img {
        background: url(../images/img-bg-02.webp);
        background-size: contain; }
      .item__content-bg {
        background: #ED5DA4; } }
    &.bg-03 {
      .item__img {
        background: url(../images/img-bg-03.webp);
        background-size: contain; }
      .item__content-bg {
        background: #7739EA; } }
    &.bg-04 {
      .item__img {
        background: url(../images/img-bg-04.webp);
        background-size: contain; }
      .item__content-bg {
        background: #2CA4BF; } }
    &.bg-05 {
      .item__img {
        background: url(../images/img-bg-05.webp);
        background-size: contain; }
      .item__content-bg {
        background: #2CA4BF; } }
    &.bg-06 {
      .item__img {
        background: url(../images/img-bg-06.webp);
        background-size: contain; }
      .item__content-bg {
        background: #7739EA; } }
    &.bg_p {
      .item__img {
        background: url(../images/speaker-accent-01.webp);
        background-size: contain; }
      .item__content-bg {
        background: #7739EA; } }
    &.bg_g {
      .item__img {
        background: url(../images/speaker-accent-02.webp);
        background-size: contain; }
      .item__content-bg {
        background: #2CA4BF; } }
    &.bg_r1 {
      .item__img {
        background: url(../images/speaker-accent-04.webp);
        background-size: contain; }
      .item__content-bg {
        background: #DC2F66; } }
    &.bg_r2 {
      .item__img {
        background: url(../images/speaker-accent-05.webp);
        background-size: contain; }
      .item__content-bg {
        background: #ED5DA4; } }
    &:hover {
      .item__content {
        color: #FFF; }
      .item__content-bg {
        opacity: 1;
        left: 0;
        width: 100%; } }
    &[data-toggle="modal"] {
      cursor: pointer; }

    &__wrap {
      margin-bottom: 16px; } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }
  @include maxtablet() {
    .item {
      .item__name {
        font-size: 16px;
        line-height: 24px; }
      .item__content {
        color: #FFF;
        min-height: 94px;
        padding: 8px 12px;
        height: 100%; }
      .item__content-bg {
        width: 100%;
        opacity: 1; }
      .item__wrap {
        display: flex;
        height: 100%;
        flex-direction: column;
        padding: 0 0 16px;
        margin: 0; } } }
  @include mobile() {
    padding-left: 8px;
    padding-right: 8px;
    .item {
      max-width: 50%;
      flex: 0 0 50%; }
    &__wrap {
      padding-top: 48px;
      padding-bottom: 48px; } } }

