.smission {
  padding-top: 96px;
  background: #333037;
  &__image {
    margin-bottom: 64px;
    img {
      display: block;
      margin: 0 auto;
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 300px; } }
  &__title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
    color: #FFF;
    margin: 0 0 40px; }
  &__list {
    background: #FFF;
    padding: 24px;
    margin: 0 0 40px;
    .title {
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;
      margin: 0 0 24px; }
    ol {
      margin: 0;
      li {
        padding: 12px 0; } } }
  &__tnc {
    color: #FFF;
    font-size: 14px;
    line-height: 20px;
    h2, h3, h4 {
      color: #FFF;
      margin: 0 0 24px; }
    ol, ul {
      margin: 0 0 24px;
      padding-left: 24px;
      &:last-child {
        margin: 0; } } }
  .banner {
    &__outline {
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 4px; }
    &__title {
      font-size: 24px;
      line-height: 30px;
      font-weight: 600;
      margin: 0 0 24px; } }
  &__banner {
    display: block;
    position: sticky;
    top: 120px;
    background-color: #FFF2F8;
    background-image: url(../images/accent-mission.webp);
    background-position: bottom right;
    background-size: auto 100px;
    background-repeat: no-repeat;
    padding: 24px;
    border: 1px solid #DC2F66;
    min-height: 290px; }
  &__left {
    display: block;
    width: 100%;
    max-width: calc(100% - 400px);
    flex: 0 0 calc(100% - 400px);
    padding-right: 60px;
    padding-bottom: 32px; }
  &__right {
    display: block;
    width: 100%;
    max-width: 400px;
    flex: 0 0 400px; }
  &__row {
    display: flex; }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }

  @include responsive(1200px) {
    &__left {
      max-width: calc(100% - 300px);
      flex: 0 0 calc(100% - 300px);
      padding-right: 30px; }
    &__right {
      max-width: 300px;
      flex: 0 0 300px; } }

  @include responsive(768px) {
    &__banner {
      position: relative;
      top: auto;
      min-height: 180px;
      background-size: auto 60px;
      .banner {
        &__outline {
          font-size: 15px;
          line-height: 24px; }
        &__title {
          font-size: 20px;
          line-height: 1.2em; } } }
    &__left {
      max-width: 100%;
      flex: 0 0 100%;
      padding-right: 0;
      padding-left: 16px;
      padding-right: 16px; }
    &__right {
      position: sticky;
      max-width: 100%;
      flex: 0 0 100%;
      bottom: 0; }
    &__row {
      flex-wrap: wrap; }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0; } } }
