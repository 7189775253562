/* ------------------------------------------------------------------------------------------------ */
/* Fonts */
/* ------------------------------------------------------------------------------------------------ */

@include fontGenerator("Inter",(300),(Inter-Light));
@include fontGenerator("Inter",(400),(Inter-Regular));
@include fontGenerator("Inter",(500),(Inter-Medium));
@include fontGenerator("Inter",(600),(Inter-SemiBold));
@include fontGenerator("Inter",(700),(Inter-Bold));
@include fontGenerator("Inter",(800),(Inter-ExtraBold));

@mixin inter-300 {
  font-family: "Inter", sans-serif;
  font-weight: 300; }

@mixin inter-400 {
  font-family: "Inter", sans-serif;
  font-weight: 400; }

@mixin inter-500 {
  font-family: "Inter", sans-serif;
  font-weight: 500; }

@mixin inter-600 {
  font-family: "Inter", sans-serif;
  font-weight: 600; }

@mixin inter-700 {
  font-family: "Inter", sans-serif;
  font-weight: 700; }

@mixin inter-800 {
  font-family: "Inter", sans-serif;
  font-weight: 800; }
