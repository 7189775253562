.cs-main {
  position: relative;
  padding-top: 128px;
  padding-bottom: 128px;
  background: #FFF;
  &__accent {
    z-index: 1;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 400px;
    top: 0;
    left: 0;
    span {
      display: block;
      position: absolute; }
    .container,
    .container-fluid {
      position: relative; }
    .l-accent-1 {
      width: 100%;
      height: 12px;
      background: #623CE0;
      position: absolute;
      top: 0;
      right: 10%; }
    .l-accent-2 {
      width: 30%;
      height: 12px;
      background: #C6B5FF;
      position: absolute;
      top: 0;
      right: 20%; }
    .l-accent-3 {
      width: 100%;
      height: 12px;
      background: #6F4AEB;
      position: absolute;
      top: 0;
      right: calc(100% - 120px); }
    .l-accent-4 {
      width: 100%;
      height: 12px;
      background: #FFCEE6;
      position: absolute;
      top: 0;
      left: 90%; }
    .accent-1 {
      display: none;
      width: 50%;
      height: 165px;
      top: 0;
      right: calc(100% - 420px);
      background: #F9F7FF; }
    .accent-2 {
      display: none;
      width: 50%;
      height: 350px;
      top: 0;
      right: 100%;
      background: #F9F7FF; }
    .accent-3 {
      display: none;
      width: 50%;
      height: 320px;
      top: 0;
      right: calc(100% - 350px);
      background: #F5F3F9; }
    .accent-4 {
      display: none;
      width: 120px;
      height: 120px;
      top: 0;
      left: 0;
      background: $primary; }
    .accent-5 {
      display: none;
      width: 50%;
      height: 170px;
      top: 0;
      right: 100%;
      background: $primary; }

    @include mobile {
      .accent-3 {
        width: 85%;
        right: auto;
        left: 0; }
      .accent-4 {
        top: -56px; }
      .accent-5 {
        top: -32px;
        right: calc(100% - 48px); } } }

  @include mobile() {
    padding-bottom: 64px; } }

.csm-desc {
  z-index: 2;
  position: relative;
  margin-bottom: 64px;
  &__title {
    font-size: 32px;
    line-height: 40px; }
  &__desc {
    font-size: 18px;
    line-height: 28.8px; }

  @include maxlarge() {
    .row > .col-md-6 {
      max-width: 66.666667%;
      flex: 0 0 66.666667%; } }

  @include mobile() {
    .row > .col-md-6 {
      max-width: 100%;
      flex: 0 0 100%; }
    &__wrap {
      padding-left: 16px;
      padding-right: 16px; } } }

.csm-socprof {
  .items {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: 16px;
    .item {
      padding-left: 8px;
      padding-right: 8px;
      width: 100%;
      max-width: 33.33%;
      flex: 0 0 33.33%;
      .title {
        display: block;
        width: 100%;
        font-size: 32px;
        line-height: 40px;
        @include inter-600(); }
      .desc {
        display: block;
        width: 100%;
        font-size: 14px;
        line-height: 20px; }
      &__wrap {
        background: #A9DEEA;
        color: #2CA4BF;
        padding: 20px;
        min-height: 150px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-align: center;
        .content {
          display: block;
          width: 100%; } }
      &:nth-child(2) {
        .item__wrap {
          background: #DECAFF;
          color: #7739EA; } }
      &:nth-child(3) {
        .item__wrap {
          background: #FFCEE6;
          color: #DC2F66; } } } }
  .socprof-video {
    position: relative;
    .video-button {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 32px;
      color: $yellow;
      @include inter-600();
      text-align: center;
      background: $text;
      transition: all .3s ease-in-out;
      span::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
        border-radius: 12px;
        width: 24px;
        height: 24px;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g id="Frame"><path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12C21.75 17.385 17.385 21.75 12 21.75C6.615 21.75 2.25 17.385 2.25 12ZM16.274 11.017C16.4492 11.1145 16.5951 11.257 16.6967 11.4299C16.7983 11.6027 16.8519 11.7995 16.8519 12C16.8519 12.2005 16.7983 12.3973 16.6967 12.5701C16.5951 12.743 16.4492 12.8855 16.274 12.983L10.671 16.096C10.4998 16.191 10.3067 16.2397 10.1109 16.2373C9.91513 16.2348 9.72336 16.1813 9.55458 16.082C9.3858 15.9828 9.24586 15.8411 9.14859 15.6712C9.05133 15.5012 9.00011 15.3088 9 15.113V8.887C9 8.03 9.921 7.487 10.671 7.904L16.274 11.017Z" fill="%23FFED51"/></g></svg>');
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat; }
      &:hover {
        background-color: darken($text, 10%);
        span::before {
          @include pulse(255,255,255,pulse-video); } } } }
  .testimonial {
    position: relative;
    height: 100%;
    background-color: #DC2F66;
    background-image: url(../images/accent-testimony.webp);
    background-repeat: no-repeat;
    background-position: bottom -24px right -30px;
    background-size: 50% auto;
    padding: 0 32px 24px;
    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
      font-size: 24px;
      line-height: 30px;
      padding: 0 24px;
      background: #C61C52;
      min-height: 120px;
      padding: 10px 56px;
      text-align: center;
      margin-left: -32px;
      margin-right: -32px; }
    &__items {
      padding: 32px 0;
      height: calc(100% - 120px);
      .item {
        position: relative;
        &__text {
          color: #FFF;
          font-size: 32px;
          line-height: 120%; }
        &__author {
          margin-top: auto;
          color: #FFF;
          margin-bottom: 32px;
          .name {
            display: block;
            font-size: 24px;
            line-height: 30px;
            font-weight: 600; }
          .job_title {
            display: block;
            font-size: 18px;
            line-height: 28px; } }
        &__wrap {
          display: flex;
          flex-direction: column;
          padding: 24px;
          height: calc(100% - 48px); } } }
    .swiper-pagination {
      display: flex;
      padding: 8px 24px;
      .swiper-pagination-bullet {
        width: 32px;
        height: 8px;
        border-radius: 0;
        background: #FFF;
        opacity: 0.2;
        margin: 0 6px;
        &-active {
          opacity: 1; } } }
    .swiper-nav {
      z-index: 11;
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
      .btn-next,
      .btn-prev {
        display: block;
        cursor: pointer;
        padding-left: 16px;
        opacity: 1;
        max-width: 80px;
        transition: all .3s ease;
        &:after {
          content: "";
          display: block;
          width: 64px;
          height: 64px;
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none"><g id="arrow"><path id="Vector" d="M38.5484 15.8415L54.7641 32.0571L38.5484 48.2727M9.34961 32.0571H54.3099" stroke="%23F3EFED" stroke-width="5.34288" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></g></svg>');
          background-repeat: no-repeat;
          background-size: contain; }
        &.swiper-button-disabled {
          opacity: 0.5;
          pointer-events: none;
          // max-width: 0
 } }          // visibility: hidden
      .btn-prev {
        transform: scaleX(-1);
        padding-left: 0;
        padding-right: 16px; } } }

  .csm-socprof__left {
    display: block;
    width: 100%;
    max-width: 40%;
    flex: 0 0 40%;
    padding-left: 8px;
    padding-right: 8px; }
  .csm-socprof__right {
    display: block;
    width: 100%;
    max-width: 60%;
    flex: 0 0 60%;
    padding-left: 8px;
    padding-right: 8px; }
  &__wrap {
    padding-bottom: 16px; }

  @include maxlarge() {
    .socprof-video {
      margin-bottom: 16px;
      .img {
        display: block;
        width: 100%;
        padding-top: 56%;
        img {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: center; } } }
    .csm-socprof__left {
      max-width: 100%;
      flex: 0 0 100%; }
    .csm-socprof__right {
      max-width: 100%;
      flex: 0 0 100%; }
    .testimonial {
      background: #DC2F66;
      &__items {
        height: auto;
        padding-bottom: 120px; } } }

  @include mobile() {
    .items {
      flex-wrap: wrap;
      margin-bottom: 0;
      .item {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 16px; } }
    .socprof-video {
      margin-bottom: 16px;
      .img {
        display: block;
        width: 100%;
        padding-top: 90%; } }
    .testimonial {
      &__title {
        font-size: 18px;
        line-height: 28px;
        min-height: 80px; }
      &__items {
        padding-top: 0;
        padding-bottom: 80px;
        .item__text {
          font-size: 24px;
          line-height: 30px; }
        .item__author {
          margin: auto 0 0;
          padding-top: 48px;
          .name {
            font-size: 18px;
            line-height: 28px; }
          .job_title {
            font-size: 16px;
            line-height: 24px; } }
        .item__wrap {
          justify-content: space-between;
          padding: 32px 0; }
        .swiper-pagination {
          display: none; }
        .swiper-nav {
          // display: none
          .btn-prev::after,
          .btn-next::after {
            width: 48px;
            height: 48px; } } } }
    &__wrap {
      padding-left: 16px;
      padding-right: 16px; } } }

.csm-speakers {
  z-index: 2;
  position: relative;
  .item-button {
    z-index: 2;
    position: absolute;
    width: 50%;
    height: 120px;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $text;
    color: $yellow;
    font-size: 18px;
    line-height: 24px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    span {
      position: relative;
      display: inline-block; }

    &:hover {
      color: $yellow; } }

  .items {
    z-index: 1;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #E7E4E8;
    .item {
      position: relative;
      display: block;
      width: 100%;
      max-width: 25%;
      flex: 0 0 25%;
      //nth modifier for size
      &:nth-child(1) {
        max-width: 50%;
        flex: 0 0 50%;
        .item__content {
          margin-bottom: 120px;
          .name {
            font-size: 24px;
            line-height: 30px; }
          .job_title {
            font-size: 16px;
            line-height: 24px; } } }
      &:nth-child(4),
      &:nth-child(5) {
        position: absolute;
        top: 50%;
        left: 50%; }
      &:nth-child(5) {
        left: 75%; }
      //nth modifier for bg
      &:nth-child(1) {
        .item__img {
          background-color: #DECAFF;
          background-image: url(../images/speaker-accent-01.webp); }
        .item__content {
          background: #FFFFFF; } }
      &:nth-child(2) {
        .item__img {
          background-color: #2CA4BF;
          background-image: url(../images/speaker-accent-02.webp); }
        .item__content {
          background: #FFFFFF; } }
      &:nth-child(3) {
        .item__img {
          background-color: #A9DEEA;
          background-image: url(../images/speaker-accent-03.webp); }
        .item__content {
          background: #F3EFED; } }
      &:nth-child(4) {
        .item__img {
          background-color: #ED5DA4;
          background-image: url(../images/speaker-accent-04.webp); }
        .item__content {
          background: #F3EFED; } }
      &:nth-child(5) {
        .item__img {
          background-color: #FFCEE6;
          background-image: url(../images/speaker-accent-05.webp); }
        .item__content {
          background: #FFFFFF; } }
      // nth modifier hide more than 5
      &:nth-child(n+6) {
        display: none; }
      &__img {
        position: relative;
        width: 100%;
        background: #2CA4BF;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        .img {
          position: relative;
          display: block;
          width: 100%;
          padding-top: 100%;
          overflow: hidden;
          img {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: contain;
            object-position: bottom center; } } }
      &__content {
        background: #FFF;
        padding: 16px 24px;
        display: flex;
        min-height: 120px;
        flex-direction: column;
        justify-content: center;
        .name {
          display: block;
          font-size: 18px;
          line-height: 24px;
          @include inter-600(); }
        .job_title {
          display: block;
          font-size: 14px;
          line-height: 20px; } }
      &__wrap {
        display: flex;
        flex-direction: column;
        &.animate {
          .item__img .img img {
            animation-name: move;
            animation-duration: 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards; }
          .item__content .content-wrap {
            animation: fade 1s ease; } } } } }

  &__box {
    position: relative; }
  &__wrap {
    position: relative;
    margin-bottom: 16px; }

  @include maxlarge() {
    .item-button {
      width: 33.33%;
      height: 50%;
      left: 66.66%;
      bottom: 0; }
    .items {
      .item {
        //nth modifier for size
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          max-width: 33.33%;
          flex: 0 0 33.33%;
          .item__content {
            margin-bottom: 0; } }
        &:nth-child(4),
        &:nth-child(5) {
          max-width: 33.33%;
          flex: 0 0 33.33%;
          position: relative;
          top: auto;
          left: auto; }
        &:nth-child(5) {
          left: auto; }
        &__content {
          padding: 16px;
          min-height: 140px; } } } }

  @include mobile() {
    .item-button {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      width: 100%;
      padding: 32px; }
    .items {
      .item {
        max-width: 50% !important;
        flex: 0 0 50% !important;
        // nth modifier hide more than 4
        &:nth-child(n+5) {
          display: none; }
        // change bg
        &:nth-child(2) {
          .item__content {
            background: #F3EFED; } }
        &:nth-child(4) {
          .item__content {
            background: #FFFFFF; } }
        &__content {
          .name {
            font-size: 18px;
            line-height: 28.8px; }
          .job_title {
            font-size: 14px;
            line-height: 20px; } } } }
    &__box {
      display: flex;
      flex-direction: column-reverse; }
    &__wrap {
      padding-left: 16px;
      padding-right: 16px; } } }

.csm-sponsor {
  z-index: 2;
  position: relative;
  &__logos {
    position: relative;
    display: block;
    width: 100%;
    max-width: 59%;
    flex: 0 0 59%;
    padding-left: 8px;
    padding-right: 8px;
    .item-button {
      z-index: 2;
      position: absolute;
      width: 40%;
      height: 50%;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $text;
      color: $yellow;
      font-size: 18px;
      line-height: 24px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;
      span {
        position: relative;
        display: inline-block; }
      &:hover {
        color: $yellow;
 } }        // background-color: darken($primary, 10%)
    .items-box {
      position: relative; }
    .items {
      z-index: 1;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid #E7E4E8;
      border-left: 1px solid #E7E4E8;
      .item {
        position: relative;
        display: block;
        width: 100%;
        max-width: 20%;
        flex: 0 0 20%;
        border-bottom: 1px solid #E7E4E8;
        border-right: 1px solid #E7E4E8;
        &:nth-child(n+9) {
          display: none; }
        &__img {
          display: flex;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          justify-content: center;
          align-items: center;
          &.animate img {
            animation: fade 1s ease; } }
        &__wrap {
          position: relative;
          display: flex;
          width: 100%;
          padding-top: 100%;
          justify-content: center;
          align-items: center; } } } }

  &__block {
    position: relative;
    display: block;
    width: 100%;
    max-width: 41%;
    flex: 0 0 41%;
    padding-left: 8px;
    padding-right: 8px;
    .text {
      padding-top: 24px;
      color: #FFF;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.64px; }
    .action {
      text-align: right;
      margin-top: auto;
      padding-top: 40px;
      .btn {
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 0;
        padding: 26px 32px; } }

    .box {
      position: relative;
      height: 100%;
      background: #1491AD;
      padding: 24px 48px; } }

  @include maxlarge() {
    .item-button {
      text-align: center; }
    &__logos {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: 16px; }
    &__block {
      max-width: 100%;
      flex: 0 0 100%;

      .box {
        padding-bottom: 80px; } } }

  @include mobile() {
    .items-box {
      display: flex;
      flex-direction: column-reverse; }
    .item-button {
      display: block;
      width: 100%;
      padding: 32px;
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto; }
    &__logos {
      padding-left: 16px;
      padding-right: 16px;
      .items {
        .item {
          max-width: 50%;
          flex: 0 0 50%; } } }
    &__block {
      padding-left: 16px;
      padding-right: 16px;
      .text {
        padding-top: 0; }
      .action {
        text-align: left;
        .btn {
          width: 100%; } } } } }
