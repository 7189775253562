.cta-footer {
  background: #322F36;
  color: #FFF;
  text-align: center;
  overflow: hidden;
  a:not(.btn) {
    color: #FFF;
    &:hover {
      color: #FFF;
      text-decoration: underline; } }

  .container-border {
    &::before {
      border-left: 1px solid rgba(144, 122, 248, 0.10); }
    &::after {
      border-right: 1px solid rgba(144, 122, 248, 0.10); } }

  &__outline {
    color: #FFF;
    padding: 8px 16px;
    border-radius: 0;
    border: 1px solid rgba(231, 228, 232, 0.30);
    display: inline-block;
    margin: 0 0 8px;
    span {
      display: inline-block;
      &+ span {
        position: relative;
        padding-left: 16px;
        margin-left: 16px;
        &::before {
          content: "";
          display: block;
          border-left: 1px solid rgba(231, 228, 232, 0.30);
          height: calc(100% + 16px);
          position: absolute;
          left: 0;
          top: -8px; } } } }

  &__title {
    color: #FFF;
    font-size: 48px;
    line-height: 120%;
    @include inter-800();
    max-width: 800px;
    margin: 0 auto 8px; }

  &__desc {
    font-size: 18px;
    line-height: 28.8px;
    max-width: 800px;
    margin: 0 auto 8px; }

  &__action {
    padding-top: 32px; }

  &__wrap {
    padding-top: 128px;
    padding-bottom: 128px; }

  @include mobile() {
    &__wrap {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 96px;
      padding-bottom: 96px; } } }

.cta-marquee {
  position: relative;
  display: flex;
  background: #0D032D;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow: hidden;
  width: 100%;
  a:not(.btn) {
    color: #FFF;
    &:hover {
      color: #FFF;
      text-decoration: underline; } }
  .items-box {
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
    user-select: none;
    gap: 0;
    position: relative;
    justify-content: flex-start;
    &:hover .items {
      animation-play-state: paused; } }
  .items {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-around;
    animation: scroll-x 30s linear infinite;

    .item {
      position: relative;
      font-size: 24px;
      line-height: 48px;
      white-space: nowrap;
      &> a {
        text-decoration: none;
        strong {
          @include inter-600(); }
        &:hover {
          text-decoration: none; } } }
    .blink {
      display: block;
      width: 48px;
      height: 48px;
      background: url(../images/ic-blink.webp);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin: 0 30px; } } }
