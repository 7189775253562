.cs-gallery {
  position: relative;
  background: #E7E4E8;
  &__title {
    font-size: 32px;
    line-height: 40px; }

  .items {
    position: relative;
    display: flex;
    margin-right: -8px;
    margin-left: -8px;
    margin-bottom: -16px;
    padding-top: 64px;
    .item {
      margin-bottom: 16px;
      .col-row {
        display: flex;
        margin-left: -8px;
        margin-right: -8px; }
      .col {
        padding-left: 8px;
        padding-right: 8px; } }

    .item-row {
      width: 100%;
      padding-left: 8px;
      padding-right: 8px;
      &:nth-child(1) {
        max-width: 30.6%; }
      &:nth-child(2) {
        max-width: 38.6%; }
      &:nth-child(3) {
        max-width: 30.7%; } } }

  .items-sm {
    display: none;
    img {
      display: block;
      width: 100%;
      height: auto; } }

  &__accent {
    z-index: 1;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 158px;
    .container {
      position: relative; }
    .accent-1 {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 200%;
      height: 34px;
      background: #FFF; }
    .accent-2 {
      position: absolute;
      display: block;
      width: 100%;
      height: 113px;
      background: #FFF;
      top: 0;
      left: calc(100% - 113px); }
    .accent-3 {
      position: absolute;
      display: block;
      width: 50%;
      height: 158px;
      background: #FFF;
      top: 0;
      left: 100%; } }

  &__wrap {
    position: relative;
    z-index: 2;
    padding-top: 128px;
    padding-bottom: 128px; }

  @include mobile() {
    &__accent {
      .accent-2 {
        left: 55%;
        height: 85px; }
      .accent-3 {
        left: 80%;
        height: 120px; } }
    .items {
      display: none; }
    .items-sm {
      padding-top: 40px;
      display: block; }
    &__wrap {
      padding-left: 16px;
      padding-right: 16px; } } }

