/* ------------------------------------------------------------------------------------------------ */
/* Header */
/* ------------------------------------------------------------------------------------------------ */

.header {
  z-index: 200;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  transition: all .3s ease-in-out;
  background: #5030BD;
  &__logo {
    z-index: 5;
    position: relative;
    a {
      display: inline-block; }
    img {
      display: block;
      height: 24px;
      width: auto; }
    // &::after
    //   content: ""
    //   display: block
    //   width: 113px
    //   height: 13px
    //   background: #845FFF
    //   position: absolute
    //   top: calc(100% + 19px)
    //   right: 0
 }    //   transition: all .3s ease-in-out
  &__menu {
    z-index: 5;
    position: relative;
    margin-left: auto;
    margin-right: 8%;
    .header__action-sm {
      display: none; }
    &> ul {
      display: flex;
      margin: 0;
      padding: 0;
      &> li {
        position: relative;
        list-style: none;
        padding: 4px 12px;
        margin: 0;
        &> a {
          position: relative;
          color: #FFF;
          display: block;
          &::before {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background: #FFF;
            position: absolute;
            right: 0;
            top: calc(100% + 4px);
            max-width: 0;
            transition: all .3s ease-in-out; }
          &:hover {
            text-decoration: none;
            &::before {
              max-width: 100%;
              left: 0; } } } } } }
  &__action {
    z-index: 5;
    position: relative;
    padding-left: 12px; }
  &__burger {
    display: none; }
  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 12px;
    transition: all .3s ease-in-out;
    min-height: 96px;
    // &::before,
    // &::after
    //   z-index: 1
    //   content: ""
    //   display: block
    //   width: 220px
    //   height: 100%
    //   position: absolute
    //   top: 0
    //   right: calc(100% - 12px)
    //   background: #5630D5
    // &::after
    //   width: 114px
    //   background: #845FFF
 }    //   right: 96px
  &-bg {
    background-color: #6F4AEB;
    background-image: url(../images/header-accent.webp);
    background-size: auto 100px;
    background-position: right top;
    background-repeat: no-repeat; }
  &-alt {
    background-color: #6F4AEB;
    background-image: url(../images/header-accent.webp);
    background-size: auto 100px;
    background-position: right top;
    background-repeat: no-repeat;
    .header__action {
      display: none; } }

  @include responsive(1200px) {
    &__menu {
      z-index: 6;
      position: fixed;
      width: 100vw;
      height: 100vh;
      background: #FFF;
      top: 0;
      left: -100%;
      transition: all .3s ease-in-out;
      padding-bottom: 180px;
      background-image: url(../images/accent-menu.webp);
      background-size: auto 40%;
      background-repeat: repeat-x;
      background-position: bottom -5px left;
      visibility: hidden;
      .header__action-sm {
        display: block;
        position: fixed;
        width: 100%;
        bottom: 0;
        padding: 48px;
        background-image: url(../images/accent-menu-bottom.webp);
        background-size: auto 124%;
        background-repeat: no-repeat;
        background-position: top left;
        background-color: #7739ea;
        .btn {
          width: 100%; } }
      &> ul {
        display: block;
        margin-top: 112px;
        margin-bottom: 64px;
        padding: 0 48px;
        &> li {
          padding: 8px;
          &> a {
            padding: 8px 0;
            color: #232933;
            font-size: 40px;
            line-height: 120%;
            font-weight: 300; } } } }
    &__action {
      margin-left: auto; }
    &__burger {
      z-index: 10;
      display: block;
      position: relative;
      width: 24px;
      height: 24px;
      margin-left: 24px;
      span {
        display: block;
        position: absolute;
        right: 0;
        width: 100%;
        height: 2px;
        background: #FFF;
        transition: all .3s ease;
        &:nth-child(1) {
          top: 3px;
          max-width: 100%; }
        &:nth-child(2) {
          top: 11px;
          max-width: 70%; }
        &:nth-child(3) {
          top: 19px;
          max-width: 90%; }
        &::hover {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3), {
            max-width: 100%; } } } }
    &__logo::after {
      display: none; }
    &__wrap {
      &::before,
      &::after {
        display: none; } } }
  @include mobile() {
    &__logo {
      img {
        height: 16px; } }
    &__menu {
      .header__action-sm {
        padding: 32px 24px; }
      &> ul {
        padding: 0 16px; } }
    &__action {
      display: none; }
    &__burger {
      margin-left: auto; }
    &__wrap {
      padding: 16px; } } }

body.header-sticky {
  .header {
    &__logo::after {
      top: calc(100% + 12px); }
    &__wrap {
      padding: 12px;
      min-height: 80px; }

    &-alt {
      background-color: #6F4AEB;
      background-image: url(../images/header-accent.webp);
      background-size: auto 100px;
      background-position: right top;
      background-repeat: no-repeat; }

    @include mobile() {
      &__wrap {
        padding: 16px; } } } }

body.menu-open {
  &.header-sticky {
    .header__logo::after {
      top: calc(100% + 19px); }
    .header__wrap {
      padding: 20px 12px; } }

  .header {
    @include responsive(1200px) {
      &__menu {
        left: 0;
        visibility: visible; }
      &__burger {
        span {
          &:nth-child(1) {
            max-width: 100%;
            top: 11px;
            transform: rotate(-45deg);
            background: #626B79; }
          &:nth-child(2) {
            max-width: 100%;
            top: 11px;
            opacity: 0;
            right: 15px;
            background: #626B79; }
          &:nth-child(3) {
            max-width: 100%;
            top: 11px;
            transform: rotate(45deg);
            background: #626B79; } } } } } }

