.sticket {
  padding-top: 96px;
  background: #343138;
  min-height: calc(100vh - 96px);
  &__sticky {
    position: sticky;
    top: 100px;
    padding-bottom: 48px;
    &.hide-banner {
      .sticket__banner {
        display: none; } } }
  &__banner {
    position: relative;
    display: block;
    background: #C61C52;
    color: #FFF;
    padding: 24px 56px;
    margin-bottom: 16px;
    .btn-copy {
      display: inline-block;
      background: rgba(0,0,0,0.6);
      border-radius: 4px;
      padding: 4px 10px;
      position: relative;
      font-size: 20px;
      line-height: 24px;
      cursor: pointer;
      &::after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -1px;
        margin-left: 8px;
        width: 16px;
        height: 16px;
        background-image: url(../images/ic-copy.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain; } }
    .title {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.64px;
      font-weight: 800;
      margin: 0 0 16px; }
    .desc {
      font-size: 24px;
      line-height: 30px; }
    .accent {
      position: absolute;
      height: 100%;
      width: 32px;
      top: 0;
      right: 0;
      span {
        display: block;
        width: 100%;
        height: 40%;
        position: absolute;
        top: 0;
        right: 0;
        // background: #F29DC6
        background: #FFF;
        opacity: 0.6;
        &:nth-child(2) {
          height: 35%;
          top: 40%;
          // background: #E85CA1
          background: #000;
          opacity: 0.15; }
        &:nth-child(3) {
          height: 25%;
          top: 75%;
          // background: #D581C9
          background: #FFF;
          opacity: 0.1; } } }
    &:hover {
      text-decoration: none; } }
  &__cards {
    .cards {
      display: flex;
      margin-left: -8px;
      margin-right: -8px; }
    .card {
      padding-right: 8px;
      padding-left: 8px;
      max-width: 50%;
      flex: 0 0 50%;
      &--green .card__head {
        background: #2CA4BF; }
      &__head {
        background: $primary;
        padding: 12px 16px;
        color: #FFF;
        .title {
          font-size: 20px;
          line-height: 30px;
          margin: 0 0 4px; }
        .price {
          font-size: 24px;
          line-height: 30px;
          font-weight: 600;
          margin: 0 0 4px; }
        .note {
          font-size: 12px;
          line-height: 20px; } }
      &__body {
        background: #FFF;
        height: 100%;
        display: flex;
        flex-direction: column;
        .list-toggle {
          position: relative;
          padding: 12px 52px 12px 16px;
          font-size: 18px;
          line-height: 28px;
          color: #7739EA;
          cursor: pointer;
          border-bottom: 1px solid #E7E4E8;
          .toggle {
            display: block;
            width: 20px;
            height: 20px;
            border: 1px solid #7739EA;
            position: absolute;
            top: 16px;
            right: 16px;
            border-radius: 10px;
            span {
              display: block;
              width: 12px;
              height: 2px;
              background: #7739EA;
              position: absolute;
              top: 8px;
              left: 3px;
              transition: all .3s ease; } } }
        .list {
          list-style: none;
          margin: 0;
          padding: 0;
          height: 100%;
          position: relative;
          &::before {
            content: "";
            display: block;
            height: 100%;
            width: 1px;
            background: #E7E4E8;
            position: absolute;
            top: 0;
            right: 52px; }
          &> li {
            font-size: 14px;
            line-height: 20px;
            display: flex;
            border-bottom: 1px solid #E7E4E8;
            .text {
              padding: 12px 12px 12px 16px;
              display: block;
              max-width: calc( 100% - 52px );
              flex: 0 0 calc( 100% - 52px );
              .addon {
                display: block;
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                color: #7739EA;
                margin-top: 4px; } }
            .check {
              padding: 12px 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              max-width: 52px;
              flex: 0 0 52px;
              span {
                display: block;
                width: 20px;
                height: 20px;
                background: url(../images/ic-check.webp);
                background-size: 20px;
                background-position: center;
                background-repeat: no-repeat; } }
            &:last-child {
              border-bottom: 0; } } }
        .note {
          border-top: 1px solid #E7E4E8;
          font-size: 12px;
          line-height: 16px;
          padding: 12px 16px; } }
      &__wrap {
        display: flex;
        flex-direction: column;
        height: 100%; } }
    &.collapse {
      .list-toggle {
          span:nth-child(1) {
            transform: rotate(180deg); }
          span:nth-child(2) {
            transform: rotate(90deg); } }
      .list {
        max-height: 0;
        opacity: 0;
        visibility: hidden; } } }


  &__left {
    max-width: 562px;
    flex: 0 0 562px;
    padding-top: 64px; }

  &__widget {
    padding-top: 40px;
    max-width: calc(100% - 562px);
    flex: 0 0 calc(100% - 562px);
    position: relative; }

  .widget-accent {
    position: absolute;
    z-index: 1;
    width: 40%;
    top: 20%;
    right: 0;
    opacity: 0.5;
    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 77%;
      background-image: url(../images/ticket-accent-01.webp);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center; }
    &:nth-child(2) {
        top: auto;
        right: auto;
        left: 0;
        bottom: 0;
        &::before {
          background-image: url(../images/ticket-accent-02.webp); } }

    iframe {
      position: relative;
      z-index: 10;
      display: block;
      max-width: 670px;
      margin: 0 auto; } }

  &__wrap {
    position: relative;
    z-index: 2;
    display: flex; }

  &__countdown {
    position: relative;
    z-index: 10;
    padding: 16px 0;
    background: #7739EA;
    color: #FFF;
    .items-box {
      position: relative;
      display: flex;
      width: 100%;
      overflow: hidden;
      user-select: none;
      gap: 0;
      position: relative;
      justify-content: flex-start;
      &:hover .items {
        animation-play-state: paused; } }
    .items {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-around;
      animation: scroll-x 30s linear infinite; }
    .countdown {
      display: flex;
      align-items: center;
      position: relative;
      .panel {
        display: block;
        text-align: center;
        padding: 0 8px;
        .num {
          display: block;
          font-size: 60px;
          line-height: normal;
          letter-spacing: -3px;
          font-weight: 700;
          padding: 8px 16px;
          min-width: 110px; }
        .text {
          display: block;
          font-size: 12px;
          line-height: 18px; } }

      &::after {
        content: "";
        display: block;
        width: 64px;
        height: 64px;
        background-image: url(../images/logomark-mekari-w.webp);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin: 0 80px; } } }

  .container-border {
    &::before,
    &::after {
      border-left: 1px solid rgba(144, 122, 248, 0.10);
      height: 100%;
      top: 0; } }

  @include responsive(1200px) {
    &__sticky {
      padding-bottom: 16px; }
    &__left,
    &__widget {
      max-width: 100%;
      flex: 0 0 100%; }
    &__left {
      padding-top: 24px;
      .sticket__sticky {
        display: flex;
        flex-direction: column;
        position: relative;
        top: auto;
        padding: 0;
        margin: 0;
        // .sticket__cards
        //   order: 1
        .sticket__banner {
          // order: 2
          margin-top: 0;
          padding: 12px 24px;
          .btn-copy {
            font-size: 12px;
            line-height: 20px;
            &::after {
              width: 14px;
              line-height: 14px; } }
          .title {
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 4px;
            br {
              display: none; } }
          .desc {
            font-size: 12px;
            line-height: 20px; } } } }

    &__widget {
      padding-top: 0; }
    &__wrap {
      flex-wrap: wrap; } }

  @include mobile() {
    &__countdown {
      .items {
        animation: none;
        width: 100%;
        &:not(:first-child) {
          display: none; } }
      .countdown {
        justify-content: space-between;
        width: 100%;
        padding: 0 16px;
        .panel {
          .num {
            font-size: 40px;
            line-height: normal;
            padding: 4px 8px;
            min-width: 64px; } }
        &::after {
          display: none; } } } }

  @include responsive(425px) {
    &__cards {
      overflow: auto hidden;
      &::-webkit-scrollbar {
        display: none; }
      .cards {
        width: 600px; } } } }
