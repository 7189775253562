.accordion-nav {
  display: flex;
  .btn-prev,
  .btn-next {
    display: block;
    width: 100%;
    max-width: 50%;
    flex: 0 0 50%;
    background: #DC2F66;
    padding: 24px;
    span {
      display: block;
      width: 40px;
      height: 40px;
      background-image: url(../images/ic-right-w.webp);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; } }
  .btn-prev {
    span {
      transform: scaleX(-1); } }
  .btn-next {
    span {
      margin-left: auto; } } }

.swhy {
  .swhy-row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px; }
  .swhy-col {
    display: block;
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 16px;
    &__icon {
      margin: 0 0 8px;
      img {
        display: block;
        height: 40px;
        width: 40px;
        object-fit: cover;
        object-position: center; } }
    &__title {
      font-size: 24px;
      line-height: 30px;
      font-weight: 600;
      margin: 0 0 8px; }
    &__desc {
      font-size: 15px;
      line-height: 24px; }
    &__wrap {
      min-height: 254px;
      background: #EFE5FF;
      padding: 24px;
      height: 100%; }
    &--1 {
      max-width: 60%;
      flex: 0 0 60%;
      .swhy-col__title {
        font-size: 40px;
        line-height: 48px;
        color: #FFF; }
      .swhy-col__wrap {
        padding: 40px;
        background-color: #2CA4BF;
        background-image: url(../images/img-sponsor-accent.webp);
        background-size: contain;
        background-position: right top;
        background-repeat: no-repeat; } }
    &--2 {
      max-width: 40%;
      flex: 0 0 40%; }
    &--3,
    &--5 {
      max-width: 30%;
      flex: 0 0 30%;
      .swhy-col__wrap {
        background-color: #FFE7F3; } }
    &--4 {
      max-width: 40%;
      flex: 0 0 40%; } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }
  &.shwy--about {
    .swhy-col {
      &--1 {
        .swhy-col__title {
          margin: 0 0 40px; }
        .swhy-col__desc {
          color: #FFF; }
        .swhy-col__wrap {
          background-color: #DC2F66;
          background-image: url(../images/img-about-accent.webp);
          background-position: right bottom;
          background-repeat: no-repeat; } }
      &--2 {
        .swhy-col__wrap {
          background-color: #EFE5FF; } }
      &--3,
      &--4 {
        max-width: 50%;
        flex: 0 0 50%;
        .swhy-col__wrap {
          background-color: #D0F1F9; } } } }

  @include maxtablet() {
    .swhy-col {
      &--1,
      &--2,
      &--3,
      &--4,
      &--5 {
        max-width: 100%;
        flex: 0 0 100%; }
      &--1 {
        .swhy-col__wrap {
          padding: 24px;
          background: #2CA4BF; } } }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; }
    &.shwy--about {
      .swhy-col {
          &--3,
          &--4 {
            max-width: 100%;
            flex: 0 0 100%; } } } } }

.shglt {
  background: #0D032D;
  color: #FFF;
  &__title {
    font-size: 40px;
    line-height: 48px;
    color: #FFF;
    font-weight: 600;
    margin: 0 0 40px;
    text-align: center; }
  .item {
    text-align: center;
    &__title {
      font-size: 40px;
      line-height: 48px;
      font-weight: 800;
      margin: 0 0 8px; }
    &__wrap {
      padding: 12px 0; } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }

  @include maxtablet() {
    &__title {
      font-size: 32px;
      line-height: 40px; }
    &__wrap {
      padding-top: 40px;
      padding-bottom: 40px; } } }

.slogos {
  background: #F6F5F4;
  &__title {
    margin: 0 0 40px;
    font-size: 40px;
    line-height: 48px;
    text-align: center; }
  &__nav {
    position: sticky;
    top: 96px;
    .nav-title {
      display: none; }
    .nav {
      display: flex;
      width: 100%;
      padding: 0;
      margin: 0;
      &__item {
        display: flex;
        flex: 1; }
      &__link {
        display: block;
        text-align: center;
        padding: 30px;
        font-size: 18px;
        line-height: 24px;
        color: #FFF;
        font-weight: 600;
        background: #322F36;
        height: 100%;
        width: 100%;
        &:hover,
        &.active {
          background: #2CA4BF; } } } }
  &__content {

    .logos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-left: -8px;
      margin-right: -8px;
      .logo {
        display: block;
        max-width: 16.666%;
        flex: 0 0 16.666%;
        padding: 8px;
        img {
          display: block;
          width: 100%;
          height: auto;
          background: #FFF; } } }
    .item {
      padding: 40px 0;
      &__title {
        font-size: 32px;
        line-height: 40px;
        font-weight: 600;
        text-align: center;
        margin: 0 0 40px; }
      &:first-child {
        padding-top: 80px; }
      &--main {
        .logos {
          .logo {
            max-width: 25%;
            flex: 0 0 25%; } } } } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }

  @include responsive(1200px) {
    &__nav {
      background: #FFF;
      padding: 12px;
      .nav-title {
        display: block;
        font-size: 18px;
        line-height: 28px;
        margin: 0 0 8px; }
      .nav {
        display: block;
        &__item {}
        &__link {
          padding: 16px;
          text-align: left;
          display: none;
          &.active {
            display: block;
            background: #322F36; } }
        &.show {
          .nav__link {
            display: block; } } } } }

  @include maxtablet() {
    .item {
      .logos {
        .logo {
          max-width: 33.333%;
          flex: 0 0 33.333%; } }
      &--main {
        .logos .logo {
          max-width: 40%;
          flex: 0 0 40%; } } }

    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } }

  @include mobile() {
    .item {
      .logos {
        .logo {
          max-width: 50%;
          flex: 0 0 50%; } }
      &--main {
        .logos .logo {
          max-width: 50%;
          flex: 0 0 50%; } } } } }

.tespons {
  max-width: 100%;
  overflow: hidden;
  .accordion-nav {
    display: none; }
  &__accordion {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 16px;
    width: 1200px;
    .item {
      display: flex;
      &__title {
        padding: 16px 40px 16px 24px;
        background: #6E6881;
        color: #FFF;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        span {
          font-size: 20px;
          line-height: 28px;
          font-weight: 600;
          writing-mode: vertical-rl;
          text-orientation: mixed;
          transform: rotate(180deg); } }
      &__content {
        display: flex;
        max-width: 0;
        max-height: 350px;
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease;
        .logo {
          width: 145px;
          img {
            width: 145px;
            height: 145px;
            object-fit: contain;
            object-position: center;
            background: #F3EFED; } }
        .content {
          position: relative;
          width: 620px;
          background: #5030BD;
          padding: 56px 64px;
          color: #FFF;
          &__quote {
            font-size: 18px;
            line-height: 24px;
            padding-bottom: 64px;
            min-width: 492px; }
          &__name {
            max-width: 80%;
            font-size: 24px;
            line-height: 30px; }
          &__job-title {
            max-width: 80%;
            font-size: 18px;
            line-height: 24px; }
          &__logo {
            position: absolute;
            width: 20%;
            padding-top: 20%;
            bottom: 0;
            right: 0;
            background: #F3EFED;
            img {
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              object-fit: contain;
              object-position: center; } } } }

      &.active {
        .item__title {
          display: none; }
        .item__content {
          max-width: 765px;
          opacity: 1;
          visibility: visible; } } } }
  &__title {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    margin: 0 0 40px;
    width: 100%; }
  &__left {
    max-width: 262px;
    flex: 0 0 262px;
    padding-right: 64px; }
  &__right {
    max-width: calc(100% - 262px);
    flex: 0 0 calc(100% - 262px); }
  &__row {
    position: relative;
    width: 100%; }
  &__wrap {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-wrap: wrap; }

  @include responsive(1200px) {
    .accordion-nav {
      display: flex; }
    &__accordion {
      display: flex;
      gap: 0;
      width: 100%;
      .item {
        display: block;
        margin-bottom: 16px;
        max-width: 0;
        opacity: 0;
        &__title {
          display: none;
          width: 100%;
          padding: 16px 24px;
          min-height: initial;
          justify-content: flex-start;
          span {
            writing-mode: inherit;
            text-orientation: inherit;
            transform: none; } }
        &__content {
          width: 100%;
          flex-direction: column;
          // max-height: 0
          max-width: 100%;
          .content {
            width: 100%;
            padding: 40px 24px;
            &__quote {
              min-width: initial; } } }
        &.active {
          max-width: 100%;
          opacity: 1;
          margin: 0;
          .item__content {
            max-width: 100%;
            max-height: initial; } } } }

    &__title {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 32px; }

    &__left,
    &__right {
      max-width: 100%;
      flex: 0 0 100%; }

    &__wrap {
      flex-wrap: wrap;
      padding-top: 32px;
      padding-bottom: 32px; } } }

.sabout {
  position: relative;
  background-color: #0D032D;
  &__date {
    display: flex;
    margin: 0 0 32px;
    span {
      display: block;
      padding: 0 6px;
      border: 1px solid #FFF;
      & + span {
        margin-left: -1px; } } }
  &__title {
    color: #FFF;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.8px;
    margin: 0 0 32px; }
  &__content {
    color: #FFF;
    max-width: 50%;
    margin-left: auto;
    padding-left: 64px;
    ul {
      padding-left: 24px;
      margin: 0; } }
  &__bg {
    background-color: #0D032D;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    span {
      display: block;
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 70%;
        height: 100%;
        background: linear-gradient(270deg, #0D032D 0%, rgba(13, 3, 45, 0.00) 100%); } } }
  &__wrap {
    position: relative;
    z-index: 2;
    padding-top: 80px;
    padding-bottom: 80px; }

  @include responsive(1200px) {
    &__bg {
      display: block;
      position: relative;
      padding-top: 40%;
      span {
        width: 100%;
        &::after {
          background: rgba(0, 0, 0, 0.3);
          width: 100%; } } }
    &__content {
      max-width: 100%;
      padding: 0; }
    &__wrap {
      padding-top: 40px;
      padding-bottom: 40px; } } }

.sstage {
  background: #0D032D;
  &__nav {
    width: 100%;
    max-width: 260px;
    flex: 0 0 260px;
    .nav-title {
      z-index: 2;
      position: relative;
      display: block;
      text-align: center;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -0.8px;
      color: #FFF;
      border: 1px solid #FFF;
      margin: 0;
      padding: 16px 0; }
    .nav {
      z-index: 1;
      position: relative;
      display: block;
      list-style: none;
      padding: 0;
      margin: 0;
      &__item {
        position: relative; }
      &__link {
        display: block;
        padding: 32px 12px;
        background-color: #FFF;
        color: #322F36;
        text-align: center;
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        border: 1px solid #E7E4E8;
        margin-top: -1px;
        transition: all .2s ease;
        &:hover,
        &.active {
          background-color: #DC2F66;
          border-color: #DC2F66;
          color: #FFF; } } } }
  &__content {
    display: flex;
    width: 100%;
    padding-left: 16px;
    .pane {
      position: relative;
      display: none;
      opacity: 0;
      transition: all .2s ease;
      &__title {
        color: #FFF;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.8px;
        font-weight: 400;
        margin: 0 0 40px; }
      &__content {
        display: block;
        width: 100%;
        margin-right: 16px;
        padding: 40px;
        color: #FFF;
        background-color: #DC2F66;
        background-image: url(../images/img-about-accent-stage.webp);
        background-size: contain;
        background-position: right 80px bottom;
        background-repeat: no-repeat; }
      &__img {
        width: 100%;
        max-width: 34.5%;
        flex: 0 0 34.5%;
        height: 100%;
        position: relative;
        img {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: center; } }
      &.show {
        opacity: 1; }
      &.active {
        display: flex; } } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex; }

  @include responsive(1200px) {
    &__nav,
    &__content {
      max-width: 100%;
      flex: 0 0 100%; }
    &__nav {
      max-width: 100%;
      .nav-title {
        font-size: 32px;
        line-height: 40px;
        border: 0;
        padding: 0;
        margin: 0 0 16px;
        text-align: left; }
      .nav-container {
        max-width: 100%;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none; } }
      .nav {
        display: block;
        display: flex;
        width: 720px;
        &__link {
          padding: 24px 16px; } } }
    &__content {
      padding: 0;
      .pane {
        padding: 16px 0;
        &__content {
          margin: 0; }
        &__img {
          display: none; } } }
    &__wrap {
      flex-wrap: wrap;
      padding-top: 40px;
      padding-bottom: 40px; } } }

.sjourney {
  overflow: hidden;
  .video-button {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 32px;
    color: $yellow;
    @include inter-600();
    text-align: center;
    background: $text;
    transition: all .3s ease-in-out;
    span::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      border-radius: 12px;
      width: 24px;
      height: 24px;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g id="Frame"><path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12C21.75 17.385 17.385 21.75 12 21.75C6.615 21.75 2.25 17.385 2.25 12ZM16.274 11.017C16.4492 11.1145 16.5951 11.257 16.6967 11.4299C16.7983 11.6027 16.8519 11.7995 16.8519 12C16.8519 12.2005 16.7983 12.3973 16.6967 12.5701C16.5951 12.743 16.4492 12.8855 16.274 12.983L10.671 16.096C10.4998 16.191 10.3067 16.2397 10.1109 16.2373C9.91513 16.2348 9.72336 16.1813 9.55458 16.082C9.3858 15.9828 9.24586 15.8411 9.14859 15.6712C9.05133 15.5012 9.00011 15.3088 9 15.113V8.887C9 8.03 9.921 7.487 10.671 7.904L16.274 11.017Z" fill="%23FFED51"/></g></svg>');
      background-size: 30px;
      background-position: center;
      background-repeat: no-repeat; }
    &:hover {
      background-color: darken($text, 10%);
      span::before {
        @include pulse(255,255,255,pulse-video); } } }
  &__accordion {
    display: flex;
    gap: 16px;
    .item {
      display: block;
      width: 100%;
      height: 480px;
      max-width: 120px;
      flex: 0 0 120px;
      transition: all .2s ease;
      position: relative;
      cursor: pointer;
      &__year {
        z-index: 2;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        color: #FFF;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        padding: 16px 24px;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        transform: rotate(180deg);
        background: rgba(0, 0, 0, 0.2); }
      &__lity {
        z-index: 3;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        display: none; }
      &__img {
        z-index: 1;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: right center; }
      &.active {
        max-width: calc(100% - 324px);
        flex: 0 0 calc(100% - 324px);
        .item__year {
          display: none; }
        .item__lity {
          display: block; } } } }

  &__title {
    font-size: 40px;
    line-height: 48px;
    margin: 0 0 40px; }
  &__left {
    width: 100%;
    max-width: 324px;
    flex: 0 0 324px;
    padding-right: 64px; }
  &__right {
    width: 100%; }
  &__wrap {
    display: flex;
    padding-top: 80px;
    padding-bottom: 80px; }

  @include responsive(1200px) {
    .video-button {
      padding: 32px 16px; }
    &__accordion {
      .item {
        height: 40vh;
        max-width: 10%;
        flex: 0 0 10%;
        display: none;
        &.active {
          max-width: 100%;
          flex: 0 0 100%;
          display: block; } } }
    &__title {
      font-size: 32px;
      line-height: 40px;
      margin: 0 0 32px; }
    &__left,
    &__right {
      max-width: 100%;
      flex: 0 0 100%; }
    &__wrap {
      flex-wrap: wrap;
      padding-top: 40px;
      padding-bottom: 40px; } } }

